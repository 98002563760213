<template>
  <div class="page__wrapper">
    <ag-grid
      style="width:100%;height:100%;"
      pagination
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :pagination-page-size="tablePageInfo.pageSize"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            v-permission="'cost_vrd:templateDownload'"
            type="primary"
            @click="handleTemplateClick"
          >
            {{ $t('operation.templateDownload') }}
          </el-button>
          <el-button
            v-permission="'cost_vrd:import'"
            type="primary"
            @click="isShowImportDialog=true"
          >
            {{ $t('operation.import') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
    <import-dialog :show-flag.sync="isShowImportDialog" />
  </div>
</template>

<script>
import Vue from 'vue'
import importDialog from './importDialog'
import pageMixins from '@/components/base/simple-table/mixin'

const BASEURL = {
  list: '/jlr/tt/vrd/list',
  delete: '/jlr/tt/vrd/delete',
  template: '/jlr/tt/vrd/template',
  download: '/download/iexp/task/download'
}

/** 操作列 */
const Operation = Vue.extend({
  inject: ['handleDeleteClick', 'handleDataSearch', 'handleExportClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="this.$t('operation.delete')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-delete" v-permission="'cost_vrd:delete'" @click="handleDeleteClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.export')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-download" v-permission="'cost_vrd:export'"
          :disabled="!(params.data.task && params.data.task.id)" @click="handleExportClick(params.data)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'CostVrd',
  components: { importDialog },
  mixins: [pageMixins],
  data () {
    return {
      searchModel: {},
      tableData: [],
      gridOptions: {},
      frameworkComponents: {},
      isShowImportDialog: false
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleDeleteClick: this.handleDeleteClick,
      handleExportClick: this.handleExportClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'version',
          type: 'Input',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('cost_vrd.version')
        },
        { slotName: 'tableOperation', col: { xs: 20, sm: 20, md: 20 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('cost_vrd.version'),
          field: 'version',
          minWidth: 200
        },
        {
          headerName: this.$t('cost_vrd.name'),
          field: 'name',
          minWidth: 220
        },
        {
          headerName: this.$t('cost_vrd.importTime'),
          field: 'updateDate',
          minWidth: 120
        },
        {
          headerName: this.$t('field.operation'),
          width: 68,
          pinned: 'right',
          suppressSizeToFit: true,
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleDeleteClick (row) {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({ type: 'success', message: this.$t('tip.deleteSuccess') })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    },
    handleTemplateClick () {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.template, {})
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleExportClick (row) {
      this.$utils.fileSaveAs(BASEURL.download, { id: row.task.id })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
