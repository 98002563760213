<template>
  <el-dialog
    top="30px"
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('cost_vrd.pageTitle') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="false"
      />
      <el-button
        type="primary"
        style="margin-left:80px;width:100px;"
        @click="uploadClickHandler"
      >
        {{ $t('operation.uploadFile') }}
      </el-button>
    </div>
    <div slot="footer">
      <el-button @click="currentShowFlag=false">
        {{ $t('operation.cancel') }}
      </el-button>
    </div>
    <simple-upload
      ref="uploader"
      url="/jlr/tt/vrd/fileUpload"
      :show-flag.sync="isShowUploader"
      file-type="excel"
      :params="formModel"
      @on-success="handleUploadSuccess"
    />
  </el-dialog>
</template>
<script>
export default {
  name: 'CostVrdImportDialog',
  props: {
    showFlag: { type: Boolean, default: false }
  },
  data () {
    return {
      formModel: {},
      // ifUpload: true,
      isShowUploader: false
    }
  },
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },
    formField () {
      return [
        {
          prop: 'version',
          type: 'Input',
          col: { xs: 10, sm: 10, md: 10 },
          label: this.$t('cost_vrd.version'),
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        }
      ]
    }
  },
  inject: ['handleDataSearch'],
  methods: {
    uploadClickHandler () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        this.isShowUploader = status
      })
    },
    handleUploadSuccess () {
      this.isShowUploader = false
      this.currentShowFlag = false
      this.$message({ type: 'success', message: this.$t('tip.uploadSuccess') })
      this.handleDataSearch()
    },
    handleDialogOpen () {},
    handleDialogClosed () {
      this.formModel = {}
      this.$refs.form.resetFields()
    }
  }
}
</script>
